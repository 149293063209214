import {useEffect, useRef, useState} from 'react';
import Dropdown from '../packages/dropdowns/dropdownBase.react';
// img
import CherryImg from './img/cherry.jpg';
import DGtalguideImg from './img/DGtalguide.jpg';
import PathwaysImg from './img/pathways.jpg';
import ProtocolsImg from './img/protocols.io.jpg';
import RapidImg from './img/rapid.jpg';
import HiveImg from './img/hive.jpg';
import WhiteImg from './img/whitetail.jpg';
// icons
import {ReactComponent as Logo} from '../icons/logo.svg';
import {ReactComponent as ArrowIcon} from '../icons/arrow-right.svg';
import {ReactComponent as GoIcon} from '../icons/logos/go.svg';
import {ReactComponent as TsIcon} from '../icons/logos/ts.svg';
import {ReactComponent as ReactIcon} from '../icons/logos/react.svg';
import {ReactComponent as JsIcon} from '../icons/logos/js.svg';
import {ReactComponent as FigmaIcon} from '../icons/logos/figma.svg';
import {ReactComponent as FlutterIcon} from '../icons/logos/flutter.svg';
import {ReactComponent as SwiftIcon} from '../icons/logos/swift.svg';
import {ReactComponent as KotlinIcon} from '../icons/logos/kotlin.svg';
import {ReactComponent as CIcon} from '../icons/logos/c.svg';
import {ReactComponent as JavaIcon} from '../icons/logos/java.svg';
import {ReactComponent as PythonIcon} from '../icons/logos/python.svg';
import {ReactComponent as OpenAIIcon} from '../icons/logos/openai.svg';
import {ReactComponent as NodeIcon} from '../icons/logos/node.svg';
import {ReactComponent as PhpIcon} from '../icons/logos/php.svg';
import {ReactComponent as PytorchIcon} from '../icons/logos/pytorch.svg';
import {ReactComponent as PandasIcon} from '../icons/logos/pandas.svg';
import {ReactComponent as AwsIcon} from '../icons/logos/aws.svg';
import {ReactComponent as AzureIcon} from '../icons/logos/azure.svg';
import {ReactComponent as GoogleIcon} from '../icons/logos/google.svg';
import {ReactComponent as DockerIcon} from '../icons/logos/docker.svg';
import {ReactComponent as JenkinsIcon} from '../icons/logos/jenkins.svg';
import {ReactComponent as Menu} from '../icons/menu.svg';
// style
import {StyleSheet, css} from 'aphrodite';

const Landing = () => {
    const [showHeader, setShowHeader] = useState<boolean>(true);
    const [onHeader, setOnHeader] = useState<boolean>(true);
    const [scrollOn, setScrollOn] = useState<null | string>(null);
    const scrollPosition = useRef<number>(0);
    const projectsRef = useRef<HTMLDivElement>(null);
    const servicesRef = useRef<HTMLDivElement>(null);
    const techRef = useRef<HTMLDivElement>(null);
    const heroRef = useRef<HTMLDivElement>(null);

    const handleHashChange = () => {
        const hash = window.location.hash;
        if (hash) {
            const part = document.querySelector(hash);
            if (part) {
                const rect = part.getBoundingClientRect();
                const scrollPos = window.scrollY || document.getElementsByTagName('html')[0].scrollTop;
                window.scrollTo({
                    top: rect.top + scrollPos - 20,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
    };

    const OpenPage = (url: string) => (e?: React.MouseEvent) => {
        e && e.preventDefault();
        e && e.stopPropagation();

        window.history.pushState({}, '', `/${url}`);
        handleHashChange();
    };

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        handleHashChange();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const currentScroll = window.pageYOffset;
            if (scrollPosition.current !== currentScroll) {
                if (currentScroll <= 0) {
                    !showHeader && setShowHeader(true);
                } else if (scrollPosition.current > currentScroll) {
                    !showHeader && setShowHeader(true);
                } else {
                    showHeader && setShowHeader(false);
                }
                scrollPosition.current = currentScroll;

                if (projectsRef.current && servicesRef.current && techRef.current) {
                    const projectsRect = projectsRef.current.getBoundingClientRect(),
                        servicesRect = servicesRef.current.getBoundingClientRect(),
                        techRect = techRef.current.getBoundingClientRect();

                    if (projectsRect.top < 0 && projectsRect.bottom > 0) {
                        setScrollOn('projects');
                    } else if (servicesRect.top < 0 && servicesRect.bottom > 0) {
                        setScrollOn('servies');
                    } else if (techRect.top < 0 && techRect.bottom > 0) {
                        setScrollOn('tech');
                    } else {
                        setScrollOn(null);
                    }
                }
            }

            if (heroRef.current) {
                const heroRect = heroRef.current.getBoundingClientRect();
                setOnHeader(heroRect.bottom >= 0);
            }
        };

        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showHeader, scrollOn]);

    const today = new Date();
    const year = today.getFullYear();

    return (
        <article className={css(Styles.page, Styles.base)}>
            <header className={css(Styles.header, !onHeader && Styles.headerLight, !showHeader && Styles.headerDown)}>
                <div className={css(Styles.bigContainer)}>
                    <div className={css(Styles.headerContainer)}>
                        <div className={css(Styles.logoContainer)}>
                            <a
                                className={css(Styles.logo, !onHeader && Styles.logoLight)}
                                href="/"
                            >
                                <Logo className={css(Styles.logoIcon)} />
                            </a>
                        </div>
                        <ul className={css(Styles.menu)}>
                            <li className={css(Styles.menuItem)}>
                                <a
                                    href="/#projects"
                                    className={css(
                                        Styles.menuLink,
                                        !onHeader && Styles.menuLinkLight,
                                        scrollOn === 'projects' && Styles.menuLinkActive
                                    )}
                                    onClick={OpenPage('#projects')}
                                >
                                    projects
                                </a>
                            </li>
                            <li className={css(Styles.menuItem)}>
                                <a
                                    href="/#services"
                                    className={css(
                                        Styles.menuLink,
                                        !onHeader && Styles.menuLinkLight,
                                        scrollOn === 'services' && Styles.menuLinkActive
                                    )}
                                    onClick={OpenPage('#services')}
                                >
                                    services
                                </a>
                            </li>
                            <li className={css(Styles.menuItem)}>
                                <a
                                    href="/#technologies"
                                    className={css(
                                        Styles.menuLink,
                                        !onHeader && Styles.menuLinkLight,
                                        scrollOn === 'tech' && Styles.menuLinkActive
                                    )}
                                    onClick={OpenPage('#technologies')}
                                >
                                    technologies
                                </a>
                            </li>
                            {typeof LOGGED_IN === 'undefined' || !LOGGED_IN ? (
                                <li className={css(Styles.menuItem)}>
                                    <a
                                        className={css(Styles.signIn, !onHeader && Styles.signInLight)}
                                        href="/signin"
                                    >
                                        <span className={css(Styles.signInText)}>Sign In</span>
                                        <ArrowIcon className={css(Styles.signInIcon)} />
                                    </a>
                                </li>
                            ) : (
                                <li className={css(Styles.menuItem)}>
                                    <a
                                        className={css(Styles.signIn, !onHeader && Styles.signInLight)}
                                        href="/projects"
                                    >
                                        <span className={css(Styles.signInText)}>Dashboard</span>
                                        <ArrowIcon className={css(Styles.signInIcon)} />
                                    </a>
                                </li>
                            )}
                        </ul>
                        <Dropdown
                            {...{
                                animation: 'fade',
                                header: () => (
                                    <div className={css(Styles.menuMobile)}>
                                        <Menu
                                            className={css(
                                                Styles.menuMobileIcon,
                                                !onHeader && Styles.menuMobileIconLight
                                            )}
                                        />
                                    </div>
                                ),
                                dropElement: () => (
                                    <ul className={css(Styles.menuMobileDrop)}>
                                        <li className={css(Styles.menuMobileDropItem)}>
                                            <a
                                                href="/#projects"
                                                className={css(
                                                    Styles.menuDropLink,
                                                    scrollOn === 'projects' && Styles.menuDropLinkActive
                                                )}
                                                onClick={OpenPage('#projects')}
                                            >
                                                projects
                                            </a>
                                        </li>
                                        <li className={css(Styles.menuMobileDropItem)}>
                                            <a
                                                href="/#services"
                                                className={css(
                                                    Styles.menuDropLink,
                                                    scrollOn === 'services' && Styles.menuDropLinkActive
                                                )}
                                                onClick={OpenPage('#services')}
                                            >
                                                services
                                            </a>
                                        </li>
                                        <li className={css(Styles.menuMobileDropItem)}>
                                            <a
                                                href="/#technologies"
                                                className={css(
                                                    Styles.menuDropLink,
                                                    scrollOn === 'tech' && Styles.menuDropLinkActive
                                                )}
                                                onClick={OpenPage('#technologies')}
                                            >
                                                technologies
                                            </a>
                                        </li>
                                    </ul>
                                ),
                            }}
                        />
                    </div>
                </div>
            </header>
            <div className={css(Styles.main)}>
                <div
                    ref={heroRef}
                    className={css(Styles.hero)}
                >
                    <div className={css(Styles.bigContainer)}>
                        <div className={css(Styles.container)}>
                            <h1 className={css(Styles.title)}>8th Byte</h1>
                            <div className={css(Styles.heroTextBlock)}>
                                <p className={css(Styles.heroText)}>
                                    We are top-notch software developers & project managers. We love creating awesome
                                    software products and are ready to bring our talent and experience to your team.
                                </p>
                            </div>
                            <a
                                className={css(Styles.heroEmail)}
                                href="mailto:admin@8thbyte.com"
                            >
                                <span className={css(Styles.heroEmailText)}>admin@8thbyte.com</span>
                                <ArrowIcon className={css(Styles.heroEmailIcon)} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className={css(Styles.bigContainer)}>
                    <div className={css(Styles.container)}>
                        <div
                            id="projects"
                            ref={projectsRef}
                            className={css(Styles.block)}
                        >
                            <h2 className={css(Styles.titleBody)}>
                                <span className={css(Styles.titleBodyBig)}>Projects</span>
                                <small className={css(Styles.titleBodySmall)}>that we are really proud of</small>
                            </h2>
                            <div className={css(Styles.boxes)}>
                                <a
                                    className={css(Styles.box, Styles.box6)}
                                    href="https://hive.ing/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={HiveImg}
                                        className={css(Styles.boxImage)}
                                        alt=""
                                    />
                                    <div className={`${css(Styles.boxOverlay)} box-overlay`}>
                                        <h3 className={css(Styles.boxTitle)}>
                                            <span className={css(Styles.boxTitleText)}>Hive.ing</span>
                                            <ArrowIcon className={css(Styles.boxTitleIcon)} />
                                        </h3>
                                        <p className={css(Styles.boxText)}>
                                            Hive.ing is your gateway to exciting career opportunities in the beauty
                                            industry.
                                        </p>
                                        <ul className={css(Styles.boxTags)}>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Software architecture</span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Web Design & Development</span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Cloud Solution</span>
                                            </li>
                                        </ul>
                                    </div>
                                </a>
                                <a
                                    className={css(Styles.box, Styles.box7)}
                                    href="https://whitetail.ai/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={WhiteImg}
                                        className={css(Styles.boxImage)}
                                        alt=""
                                    />
                                    <div className={`${css(Styles.boxOverlay)} box-overlay`}>
                                        <h3 className={css(Styles.boxTitle)}>
                                            <span className={css(Styles.boxTitleText)}>Whitetail AI</span>
                                            <ArrowIcon className={css(Styles.boxTitleIcon)} />
                                        </h3>
                                        <p className={css(Styles.boxText)}>
                                            Whitetail AI allows customers to effortlessly transform their data into
                                            actionable insights through secure data collection, AI analysis, and
                                            expert-led strategic impact detection.
                                        </p>
                                        <ul className={css(Styles.boxTags)}>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Software Architecture</span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Web Design & Development</span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Cloud Solution</span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>AI & Machine Learning</span>
                                            </li>
                                        </ul>
                                    </div>
                                </a>
                                <a
                                    href="https://protocols.io"
                                    className={css(Styles.box, Styles.box1)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={ProtocolsImg}
                                        className={css(Styles.boxImage)}
                                        alt=""
                                    />
                                    <div className={`${css(Styles.boxOverlay)} box-overlay`}>
                                        <h3 className={css(Styles.boxTitle)}>
                                            <span className={css(Styles.boxTitleText)}>protocols.io</span>
                                            <ArrowIcon className={css(Styles.boxTitleIcon)} />
                                        </h3>
                                        <p className={css(Styles.boxText)}>
                                            A secure platform for developing and sharing reproducible methods.
                                        </p>
                                        <p className={css(Styles.boxText)}>Acquired by Springer Nature.</p>
                                        <ul className={css(Styles.boxTags)}>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Software Architecture</span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>
                                                    Web Design and Development
                                                </span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Cloud Solution</span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Security & HIPAA</span>
                                            </li>
                                        </ul>
                                    </div>
                                </a>
                                <a
                                    className={css(Styles.box, Styles.box2)}
                                    href="https://dgtalguide.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={DGtalguideImg}
                                        className={css(Styles.boxImage)}
                                        alt=""
                                    />
                                    <div className={`${css(Styles.boxOverlay)} box-overlay`}>
                                        <h3 className={css(Styles.boxTitle)}>
                                            <span className={css(Styles.boxTitleText)}>DGtalguide</span>
                                            <ArrowIcon className={css(Styles.boxTitleIcon)} />
                                        </h3>
                                        <p className={css(Styles.boxText)}>
                                            Extraordinary self-guided tours and day trips.
                                        </p>
                                        <ul className={css(Styles.boxTags)}>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Software Architecture</span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>
                                                    Web Design and Development
                                                </span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Mobile Development</span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Cloud Solution</span>
                                            </li>
                                        </ul>
                                    </div>
                                </a>
                                <a
                                    className={css(Styles.box, Styles.box3)}
                                    href="https://glamsquad.com"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={CherryImg}
                                        className={css(Styles.boxImage)}
                                        alt=""
                                    />
                                    <div className={`${css(Styles.boxOverlay)} box-overlay`}>
                                        <h3 className={css(Styles.boxTitle)}>
                                            <span className={css(Styles.boxTitleText)}>Cherry</span>
                                            <ArrowIcon className={css(Styles.boxTitleIcon)} />
                                        </h3>
                                        <p className={css(Styles.boxText)}>An Uber for manicure services. </p>
                                        <p className={css(Styles.boxText)}>Acquired by Glamsquad.</p>

                                        <ul className={css(Styles.boxTags)}>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Software Architecture</span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>
                                                    Web Design and Development
                                                </span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Mobile Development</span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Cloud Solution</span>
                                            </li>
                                        </ul>
                                    </div>
                                </a>

                                <a
                                    className={css(Styles.box, Styles.box4)}
                                    href="https://www.cellularagricultureaustralia.org"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={PathwaysImg}
                                        className={css(Styles.boxImage)}
                                        alt=""
                                    />
                                    <div className={`${css(Styles.boxOverlay)} box-overlay`}>
                                        <h3 className={css(Styles.boxTitle)}>
                                            <span className={css(Styles.boxTitleText)}>Pathways</span>
                                            <ArrowIcon className={css(Styles.boxTitleIcon)} />
                                        </h3>
                                        <p className={css(Styles.boxText)}>
                                            Discover your next step into cellular agriculture!
                                        </p>
                                        <ul className={css(Styles.boxTags)}>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>
                                                    Web Design and Development
                                                </span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Cloud Solution</span>
                                            </li>
                                        </ul>
                                    </div>
                                </a>
                                <a
                                    className={css(Styles.box, Styles.box5)}
                                    href="https://rapidmpy.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={RapidImg}
                                        className={css(Styles.boxImage)}
                                        alt=""
                                    />
                                    <div className={`${css(Styles.boxOverlay)} box-overlay`}>
                                        <h3 className={css(Styles.boxTitle)}>
                                            <span className={css(Styles.boxTitleText)}>Rapid MPY</span>
                                            <ArrowIcon className={css(Styles.boxTitleIcon)} />
                                        </h3>
                                        <p className={css(Styles.boxText)}>
                                            A cloud-based, smartphone application which uses Machine Learning/Artificial
                                            Intelligence (AI) to determine carbon steel corrosion coupon rates (MPY)
                                            quickly and accurately.
                                        </p>
                                        <ul className={css(Styles.boxTags)}>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Software Architecture</span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>Mobile Development</span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>
                                                    Web Design and Development
                                                </span>
                                            </li>
                                            <li className={css(Styles.boxTag)}>
                                                <span className={css(Styles.boxTagText)}>AI & Machine Learning</span>
                                            </li>
                                        </ul>
                                    </div>
                                </a>
                            </div>
                            <div className={css(Styles.bodyBottom)}>more projects coming soon</div>
                        </div>
                        <div
                            id="services"
                            ref={servicesRef}
                            className={css(Styles.block)}
                        >
                            <h2 className={css(Styles.titleBody)}>
                                <span className={css(Styles.titleBodyBig)}>What we do</span>
                                <small className={css(Styles.titleBodySmall)}>
                                    <ArrowIcon className={css(Styles.titleBodySmallIcon)} />
                                    <span>services we perform perfectly</span>
                                </small>
                            </h2>
                            <ul className={css(Styles.points)}>
                                <li className={css(Styles.point)}>
                                    <div className={css(Styles.pointNumber)}>01</div>
                                    <div className={css(Styles.pointContent)}>
                                        <h3 className={css(Styles.pointTitle)}>software architecture</h3>
                                        <p className={css(Styles.pointText)}>
                                            We can design a product architecture from scratch or extend your current
                                            systems. Our highly modular and loosely-coupled code manages code complexity
                                            and allows for quick reusability.
                                        </p>
                                    </div>
                                </li>
                                <li className={css(Styles.point)}>
                                    <div className={css(Styles.pointNumber)}>02</div>
                                    <div className={css(Styles.pointContent)}>
                                        <h3 className={css(Styles.pointTitle)}>mobile & web</h3>
                                        <p className={css(Styles.pointText)}>
                                            We are pros at creating custom end-to-end enterprise and consumer mobile and
                                            web platforms: vision, architecture, quality assurance, deployment,
                                            maintenance.
                                        </p>
                                    </div>
                                </li>
                                <li className={css(Styles.point)}>
                                    <div className={css(Styles.pointNumber)}>03</div>
                                    <div className={css(Styles.pointContent)}>
                                        <h3 className={css(Styles.pointTitle)}>cloud solutions</h3>
                                        <p className={css(Styles.pointText)}>
                                            Cloud infrastructure setup, migration, cloud application development and
                                            cost optimization.
                                        </p>
                                    </div>
                                </li>
                                <li className={css(Styles.point)}>
                                    <div className={css(Styles.pointNumber)}>04</div>
                                    <div className={css(Styles.pointContent)}>
                                        <h3 className={css(Styles.pointTitle)}>interface design</h3>
                                        <p className={css(Styles.pointText)}>
                                            We create a unique design concept and build a design system that ensures
                                            interface consistency and minimal development time.
                                        </p>
                                    </div>
                                </li>
                                <li className={css(Styles.point)}>
                                    <div className={css(Styles.pointNumber)}>05</div>
                                    <div className={css(Styles.pointContent)}>
                                        <h3 className={css(Styles.pointTitle)}>security & HIPAA</h3>
                                        <p className={css(Styles.pointText)}>
                                            We have experience in developing secure platforms and delivering HIPAA
                                            compliant products.
                                        </p>
                                    </div>
                                </li>
                                <li className={css(Styles.point)}>
                                    <div className={css(Styles.pointNumber)}>06</div>
                                    <div className={css(Styles.pointContent)}>
                                        <h3 className={css(Styles.pointTitle)}>third party integrations</h3>
                                        <p className={css(Styles.pointText)}>
                                            Integrating with third-party solutions is a part of many products. We have
                                            experience with payment solutions, analytics and data collection, mapping
                                            software, and can build a custom interface with any API your product may
                                            require.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div
                            id="technologies"
                            ref={techRef}
                            className={css(Styles.block)}
                        >
                            <h2 className={css(Styles.titleBody)}>
                                <span className={css(Styles.titleBodyBig)}>Technologies</span>
                                <small className={css(Styles.titleBodySmall)}>our magic or what we use</small>
                            </h2>
                            <div className={css(Styles.techTitle)}>
                                <span className={css(Styles.techTitleText)}>web</span>
                            </div>
                            <ul className={css(Styles.techList)}>
                                <li className={css(Styles.techListItem)}>
                                    <GoIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>Golang</span>
                                </li>
                                <li className={css(Styles.techListItem)}>
                                    <TsIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>TypeScript</span>
                                </li>
                                <li className={css(Styles.techListItem)}>
                                    <ReactIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>React</span>
                                </li>
                                <li className={css(Styles.techListItem)}>
                                    <JsIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>JavaScript</span>
                                </li>
                                <li className={css(Styles.techListItem)}>
                                    <FigmaIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>Figma</span>
                                </li>
                            </ul>
                            <div className={css(Styles.techTitle)}>
                                <span className={css(Styles.techTitleText)}>mobile</span>
                            </div>
                            <ul className={css(Styles.techList)}>
                                <li className={css(Styles.techListItem)}>
                                    <FlutterIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>Flutter</span>
                                </li>
                                <li className={css(Styles.techListItem)}>
                                    <SwiftIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>Swift</span>
                                </li>
                                <li className={css(Styles.techListItem)}>
                                    <KotlinIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>Kotlin</span>
                                </li>
                            </ul>
                            <div className={css(Styles.techTitle)}>
                                <span className={css(Styles.techTitleText)}>other</span>
                            </div>
                            <ul className={css(Styles.techList)}>
                                <li className={css(Styles.techListItem)}>
                                    <CIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>C++</span>
                                </li>
                                <li className={css(Styles.techListItem)}>
                                    <JavaIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>Java</span>
                                </li>
                                <li className={css(Styles.techListItem)}>
                                    <NodeIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>NodeJS</span>
                                </li>
                                <li className={css(Styles.techListItem)}>
                                    <PhpIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>PHP</span>
                                </li>
                            </ul>
                            <div className={css(Styles.techTitle)}>
                                <span className={css(Styles.techTitleText)}>AI & Machine Learning</span>
                            </div>
                            <ul className={css(Styles.techList)}>
                                <li className={css(Styles.techListItem)}>
                                    <OpenAIIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>ChatGPT</span>
                                </li>
                                <li className={css(Styles.techListItem)}>
                                    <PythonIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>Python</span>
                                </li>
                                <li className={css(Styles.techListItem)}>
                                    <PytorchIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>PyTorch</span>
                                </li>
                                <li className={css(Styles.techListItem)}>
                                    <PandasIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>pandas</span>
                                </li>
                            </ul>
                            <div className={css(Styles.techTitle)}>
                                <span className={css(Styles.techTitleText)}>cloud & devops</span>
                            </div>
                            <ul className={css(Styles.techList)}>
                                <li className={css(Styles.techListItem)}>
                                    <AwsIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>Amazon Web Services</span>
                                </li>
                                <li className={css(Styles.techListItem)}>
                                    <AzureIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>Azure</span>
                                </li>
                                <li className={css(Styles.techListItem)}>
                                    <GoogleIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>Google Cloud</span>
                                </li>
                                <li className={css(Styles.techListItem)}>
                                    <DockerIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>Docker</span>
                                </li>
                                <li className={css(Styles.techListItem)}>
                                    <JenkinsIcon className={css(Styles.techListIcon)} />
                                    <span className={css(Styles.techListText)}>Jenkins</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={css(Styles.footer)}>
                <div className={css(Styles.bigContainer)}>
                    <div className={css(Styles.container)}>
                        <div className={css(Styles.footerTop)}>
                            <div className={css(Styles.footerTopTextBlock)}></div>
                        </div>
                        <a
                            className={css(Styles.heroEmail)}
                            href="mailto:admin@8thbyte.com"
                        >
                            <span className={css(Styles.heroEmailText)}>admin@8thbyte.com</span>
                            <ArrowIcon className={css(Styles.heroEmailIcon)} />
                        </a>
                        <div className={css(Styles.footerBottom)}>
                            <a
                                className={css(Styles.footerBottomLink)}
                                href="privacy"
                            >
                                Privacy policy
                            </a>
                            <p className={css(Styles.footerBottomText)}>Copyright {year} 8th Byte LLC.</p>
                            <p className={css(Styles.footerBottomText)}>All rights reserved.</p>
                        </div>
                    </div>
                    <div
                        className={css(Styles.goTop)}
                        onClick={scrollTop}
                    >
                        <ArrowIcon className={css(Styles.goTopIcon)} />
                    </div>
                    <Logo className={css(Styles.footerLogo)} />
                </div>
            </div>
        </article>
    );
};

const Styles = StyleSheet.create({
    page: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: '#FFFFFF',
    },
    base: {
        fontFamily: "'Syne', sans-serif",
        fontSize: 10,
        '@media only screen and (min-width: 1681px)': {
            fontSize: '0.61vw',
        },
    },
    header: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        padding: '1.8em 0',
        background: '#1F1F1F',
        transition: 'transform 0.4s',
        zIndex: 10,
    },
    headerLight: {
        background: '#fff',
    },
    headerDown: {
        transform: 'translate3d(0, -100%, 0)',
    },
    bigContainer: {
        position: 'relative',
        width: '160em',
        maxWidth: '100%',
        margin: '0 auto',
        padding: '0 2em',
    },
    container: {
        margin: '0 8em',
        padding: '0 2em',
        '@media(max-width: 700px)': {
            margin: 0,
        },
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    menu: {
        display: 'flex',
        alignItems: 'center',
        '@media(max-width: 960px)': {
            display: 'none',
        },
    },
    menuItem: {
        marginLeft: '6em',
        listStyle: 'none',
    },
    menuLink: {
        fontSize: '2em',
        fontWeight: 500,
        color: '#ffffff',
        textDecoration: 'none',
        cursor: 'pointer',
        ':hover': {
            textDecoration: 'line-through',
        },
    },
    menuLinkActive: {
        textDecoration: 'line-through',
    },
    menuDropLink: {
        fontSize: '2em',
        fontWeight: 500,
        color: '#000',
        textDecoration: 'none',
        cursor: 'pointer',
        ':hover': {
            textDecoration: 'line-through',
        },
    },
    menuDropLinkActive: {
        textDecoration: 'line-through',
    },
    menuLinkLight: {
        color: '#000',
    },
    menuMobile: {
        display: 'none',
        cursor: 'pointer',
        '@media(max-width: 960px)': {
            display: 'block',
        },
    },
    menuMobileIcon: {
        display: 'block',
        width: 36,
        height: 36,
        fill: '#fff',
    },
    menuMobileIconLight: {
        fill: '#000',
    },
    menuMobileDrop: {
        listStyle: 'none',
        padding: '10px 0',
        margin: 0,
        position: 'absolute',
        top: '100%',
        right: 0,
        minWidth: '100%',
        background: '#fff',
        borderRadius: 5,
        boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px',
        userSelect: 'none',
    },
    menuMobileDropItem: {
        padding: '5px 10px',
    },
    signIn: {
        display: 'flex',
        width: '18.4em',
        height: '5.6em',
        fontWeight: 500,
        color: '#ffffff',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #ffffff',
        borderRadius: '4em',
        cursor: 'pointer',
    },
    signInLight: {
        color: '#000',
        border: '1px solid #000',
    },
    signInText: {
        fontSize: '1.6em',
    },
    signInIcon: {
        display: 'block',
        width: '0.8em',
        height: 'auto',
        marginLeft: '1.5em',
    },
    logoContainer: {
        display: 'block',
        flexGrow: 1,
    },
    logo: {
        display: 'inline-block',
        fill: '#ffffff',
    },
    logoLight: {
        fill: '#000',
    },
    logoIcon: {
        display: 'block',
        width: '5em',
        height: 'auto',
    },
    main: {
        flexGrow: 1,
    },
    hero: {
        padding: '18em 0 8em 0',
        color: '#ffffff',
        background: '#1F1F1F',
    },
    title: {
        margin: 0,
        fontSize: '15em',
        fontWeight: 600,
        '@media(max-width: 650px)': {
            fontSize: '12em',
        },
    },
    heroTextBlock: {
        maxWidth: '56em',
        margin: '5em 0 23em 20em',
        '@media(max-width: 790px)': {
            margin: '5em 0 23em 0',
        },
    },
    heroText: {
        margin: 0,
        fontSize: '3.4em',
        fontWeight: 500,
    },
    heroEmail: {
        display: 'inline-flex',
        color: '#ffffff',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
    },
    heroEmailText: {
        fontSize: '2em',
        fontWeight: 500,
    },
    heroEmailIcon: {
        display: 'block',
        width: '0.8em',
        height: 'auto',
        marginLeft: '1em',
    },
    block: {
        marginBottom: '12em',
    },
    titleBody: {
        display: 'flex',
        fontSize: '1em',
        margin: '0 0 6em 0',
        color: '#000000',
        alignItems: 'center',
        justifyContent: 'space-between',
        textTransform: 'lowercase',
        '@media(max-width: 1050px)': {
            display: 'block',
        },
    },
    titleBodyBig: {
        fontSize: '15em',
        fontWeight: 600,
        '@media(max-width: 1350px)': {
            fontSize: '13em',
        },
        '@media(max-width: 1050px)': {
            fontSize: '11em',
        },
        '@media(max-width: 870px)': {
            fontSize: '9em',
        },
        '@media(max-width: 730px)': {
            fontSize: '6em',
        },
        '@media(max-width: 500px)': {
            fontSize: '4em',
        },
    },
    titleBodySmall: {
        display: 'flex',
        fontSize: '2em',
        fontWeight: 400,
        alignItems: 'center',
    },
    titleBodySmallIcon: {
        display: 'block',
        marginRight: '0.5em',
        width: '0.25em',
        height: 'auto',
        transform: 'rotate(90deg)',
    },
    boxes: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, calc(50% - 1em))',
        gridTemplateRows: 'repeat(4, 43em)',
        gap: '2em',
        '@media(max-width: 1050px)': {
            gridTemplateColumns: 'repeat(1, 100%)',
        },
    },
    box: {
        position: 'relative',
        cursor: 'pointer',
        ':nth-child(1n)  .box-overlay': {
            opacity: 0,
            transition: 'opacity 0.25s',
        },
        ':hover': {
            ':nth-child(1n)  .box-overlay': {
                opacity: 1,
            },
        },
    },
    box1: {
        gridColumn: 'span 1',
        gridRow: 'span 1',
    },
    box2: {
        gridColumn: 'span 1',
        gridRow: 'span 2',
    },
    box3: {
        gridColumn: 'span 1',
        gridRow: 'span 1',
    },
    box4: {
        gridColumn: 'span 1',
        gridRow: 'span 1',
    },
    box5: {
        gridColumn: 'span 1',
        gridRow: 'span 1',
    },
    box6: {
        gridColumn: 'span 1',
        gridRow: 'span 1',
    },
    box7: {
        gridColumn: 'span 1',
        gridRow: 'span 1',
    },
    boxImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'top',
    },
    boxOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        padding: '4em',
        background: 'rgba(231, 231, 231, 0.75)',
        backdropFilter: 'blur(15px)',
        zIndex: 2,
        '@media(max-width: 1250px)': {
            padding: '2em',
        },
        '@media(max-width: 1050px)': {
            padding: '4em',
        },
        '@media(max-width: 870px)': {
            padding: '2em',
        },
    },
    boxTitle: {
        display: 'flex',
        margin: 0,
        fontSize: '1em',
        fontWeight: 600,
        color: '#000',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    boxTitleText: {
        fontSize: '4em',
        '@media(max-width: 870px)': {
            fontSize: '3em',
        },
    },
    boxTitleIcon: {
        display: 'block',
        width: '1.6em',
        height: 'auto',
    },
    boxText: {
        fontSize: '2em',
        fontWeight: 400,
        color: '#000',
        '@media(max-width: 870px)': {
            fontSize: '1.5em',
        },
    },
    boxTags: {
        display: 'flex',
        margin: '0 -0.5em',
        padding: 0,
        flexWrap: 'wrap',
        listStyle: 'none',
        '@media(max-width: 550px)': {
            display: 'none',
        },
    },
    boxTag: {
        margin: '0.5em',
        padding: '1.2em 2em',
        fontWeight: 600,
        color: '#000',
        borderRadius: '2.5em',
        background: '#ffffff',
        '@media(max-width: 870px)': {
            padding: '1em 1.5em',
            borderRadius: '1.5em',
        },
    },
    boxTagText: {
        fontSize: '1.4em',
        '@media(max-width: 1250px)': {
            fontSize: '1.2em',
        },
        '@media(max-width: 1050px)': {
            fontSize: '1.4em',
        },
        '@media(max-width: 870px)': {
            fontSize: '1.2em',
        },
    },
    bodyBottom: {
        marginTop: '4em',
        fontSize: '2.4em',
        fontWeight: 400,
        color: '#000',
    },
    points: {
        margin: '0 0 -5em 0',
        padding: 0,
        listStyle: 'none',
    },
    point: {
        display: 'flex',
        color: '#000',
        paddingBottom: '5em',
        marginBottom: '5em',
        borderBottom: '1px solid #e6e6ee',
    },
    pointNumber: {
        marginRight: '4em',
        fontSize: '2em',
        fontWeight: 600,
        '@media(max-width: 500px)': {
            marginRight: '1em',
        },
    },
    pointContent: {
        display: 'flex',
        '@media(max-width: 700px)': {
            display: 'block',
        },
    },
    pointTitle: {
        width: '50%',
        margin: 0,
        fontSize: '3.4em',
        fontWeight: 600,
        '@media(max-width: 700px)': {
            width: '100%',
        },
    },
    pointText: {
        width: '50%',
        margin: 0,
        fontSize: '2.2em',
        fontWeight: 600,
        '@media(max-width: 700px)': {
            width: '100%',
        },
    },
    techTitle: {
        paddingBottom: '0.5em',
        fontWeight: 600,
        borderBottom: '1px solid #e6e6ee',
    },
    techTitleText: {
        fontSize: '1.875em',
    },
    techList: {
        display: 'flex',
        margin: 0,
        padding: 0,
        flexWrap: 'wrap',
        listStyle: 'none',
    },
    techListItem: {
        display: 'flex',
        width: '20%',
        padding: '4em 0',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '@media(max-width: 500px)': {
            width: '25%',
        },
    },
    techListText: {
        fontSize: '1.5em',
        textAlign: 'center',
    },
    techListIcon: {
        display: 'block',
        width: '8em',
        height: 'auto',
        marginBottom: '1em',
        filter: 'grayscale(100%)',
        ':hover': {
            filter: 'grayscale(0%)',
        },
        '@media(max-width: 600px)': {
            width: '6em',
        },
        '@media(max-width: 500px)': {
            width: '4em',
        },
    },
    footer: {
        position: 'relative',
        padding: '7em 0',
        background: '#1F1F1F',
    },
    footerTop: {
        display: 'flex',
        marginBottom: '16em',
        alignItems: 'center',
        justifyContent: 'center',
        '@media(max-width: 600px)': {
            flexDirection: 'column',
        },
        '@media(max-width: 450px)': {
            marginBottom: '8em',
        },
    },
    footerTopTextBlock: {
        maxWidth: '21em',
        marginRight: '3.5em',
        '@media(max-width: 600px)': {
            maxWidth: 'initial',
            marginRight: 0,
            marginBottom: 20,
        },
    },
    footerTopText: {
        fontSize: '2em',
        fontWeight: 400,
        color: '#ffffff',
    },
    footerEmail: {
        display: 'inline-flex',
        fontSize: '2em',
        fontWeight: 500,
        color: '#ffffff',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'underline',
    },
    footerEmailIcon: {
        display: 'block',
        width: '0.8em',
        height: 'auto',
        marginLeft: '1em',
    },
    footerBottom: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: '4em',
        color: '#B0B0B0',
        justifyContent: 'flex-end',
        '@media(max-width: 650px)': {
            display: 'block',
        },
    },
    footerBottomText: {
        margin: 0,
        fontSize: '1.6em',
    },
    footerBottomLink: {
        marginLeft: '9em',
        fontSize: '1.6em',
        color: '#B0B0B0',
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'underline',
        },
        '@media(max-width: 650px)': {
            marginLeft: 0,
            marginBottom: '2em',
        },
    },
    goTop: {
        position: 'absolute',
        bottom: 0,
        left: '2em',
        display: 'flex',
        width: '5em',
        height: '5em',
        color: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#ffffff',
        borderRadius: '50%',
        cursor: 'pointer',
        '@media(max-width: 450px)': {
            bottom: '-6em',
        },
    },
    goTopIcon: {
        display: 'block',
        width: '0.7em',
        height: 'auto',
        transform: 'rotate(-90deg)',
    },
    footerLogo: {
        position: 'absolute',
        bottom: 0,
        right: '2em',
        display: 'block',
        width: '27em',
        height: 'auto',
        fill: '#fff',
        '@media(max-width: 650px)': {
            width: '12em',
        },
    },
});

export default Landing;
