import Landing from './landing/landing.react';
import ReactGA from "react-ga4";
import '../src/css/base.css';
import '../src/css/animations.css';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-VZBJ336WZS');
}

function App() {
  return (
    <Landing />
  );
}

export default App;
